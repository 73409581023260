import React from "react";
import Image from "next/image";
import styled from "@emotion/styled";
import Logo from "components/Layout/Logo";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  @media (max-width: 500px) {
    display: block;
    justify-content: baseline;
    padding: 20px 20px 30px;
    height: auto;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 380px;
  padding: 50px 40px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);

  @media (max-width: 500px) {
    padding: 20px;
  }
`;

const Message = styled.p`
  width: 100%;
  margin: 10px 0 0;
  font-size: 20px;
  text-align: center;
`;

const LogoContainer = styled.div`
  width: 200px;
  margin: 10px 0 20px;

  @media (max-width: 800px) {
    margin: 30px 0;
  }
`;

export default function NotFoundPage() {
  return (
    <Container>
      <Content>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <Image src="/images/illustrations/not-found.svg" width="200px" height="200px" alt="" />
        <Message>Esta página não existe. Verifique o endereço e tente novamente.</Message>
      </Content>
    </Container>
  );
}
